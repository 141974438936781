import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import get from 'lodash/get';
import moment from 'moment';
import ctl from 'helpers/countries';

const AreaChart = ({ locale, data: values }) => {
  const ref = useRef();

  const data = useMemo(() => {
    moment.locale(locale);

    // Inicialización de estructura de datos
    return values.reduce(
      (acum, actual) => {
        if (!actual.country || actual.warranties == null) {
          return acum; // Saltar valores no válidos
        }

        const newLabel = ctl(actual.country);
        const newData = actual.warranties;

        return {
          labels: [...acum.labels, newLabel], // Agregar nuevas etiquetas
          datasets: [
            {
              ...acum.datasets[0], // Mantener propiedades previas
              data: [...acum.datasets[0].data, newData], // Agregar nuevo dato
            },
          ],
        };
      },
      {
        labels: [],
        datasets: [
          {
            barThickness: 'flex',
            backgroundColor: [
              '#36A2EB',
              '#FDCC56',
              '#FB6283',
              '#1A888A',
              '#094D8C',
              '#A2208D',
              '#06195B',
              '#4AC0C0',
              '#CACBCF',
              '#FC9F40',
              '#f58d74',
              '#bd7cb2',
              '#595959',
              '#7e6aaf',
              '#afd98f',
            ],
            data: [],
          },
        ],
      },
    );
  }, [values, locale]);

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: 'bar',
      options: {
        title: { display: true },
        aspectRatio: 1.25,
        tooltips: {
          intersect: false,
          mode: 'index',
          xPadding: 10,
          yPadding: 10,
        },
        legend: { display: false },
        scales: {
          xAxes: [{ display: true, gridLines: true, ticks: { padding: 10 } }],
          yAxes: [{ display: true, gridLines: { offsetGridLines: true } }],
        },
        layout: { padding: { left: 10, right: 10, top: 0, bottom: 0 } },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={ref} />;
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'en'
      : 'es',
});

export default connect(matStateToProps)(AreaChart);
