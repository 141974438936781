import * as Yup from 'yup';
import { EUROPE } from 'enums/regions';

export default (intl) => {
  const country = window.globalData.country;
  const isEuropeCountry = EUROPE.includes(country);

  if (isEuropeCountry) {
    return Yup.object().shape({
      project: Yup.object().shape({
        name: Yup.string().required(
          intl.formatMessage({ id: 'Debe ingresar un nombre' }),
        ),
        type: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
          .typeError(
            intl.formatMessage({ id: 'Debe seleccionar un tipo válido' }),
          ),
        postalCode: Yup.string().required(
          intl.formatMessage({ id: 'Debe ingresar un código postal' }),
        ),
        state: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar un país' }))
          .typeError(
            intl.formatMessage({ id: 'Debe seleccionar un país válido' }),
          ),
        needCase: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
          .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
      }),
    });
  } else {
    return Yup.object().shape({
      project: Yup.object().shape({
        name: Yup.string().required(
          intl.formatMessage({ id: 'Debe ingresar un nombre' }),
        ),
        type: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
          .typeError(
            intl.formatMessage({ id: 'Debe seleccionar un tipo válido' }),
          ),
        postalCode: Yup.string().required(
          intl.formatMessage({ id: 'Debe ingresar un código postal' }),
        ),
        state: Yup.string()
          .required(intl.formatMessage({ id: 'Debe seleccionar un país' }))
          .typeError(
            intl.formatMessage({ id: 'Debe seleccionar un país válido' }),
          ),
      }),
    });
  }
};
