import React from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { EUROPE } from 'enums/regions';

import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const Actions = ({ push, value, ...props }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadFile = () => {
    const endUserFileUrl = localStorage.getItem('endUserFileUrl');
    if (endUserFileUrl) {
      window.open(endUserFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  const handleDownload = () => {
    const glassCheckFileUrl = localStorage.getItem('glassCheckFileUrl');
    if (glassCheckFileUrl) {
      window.open(glassCheckFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        &bull;&bull;&bull;
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => push(`/3m/installer/view/${value}`)}>
          <VisibilityIcon className={classes.icon} />
          <FormattedMessage id="Ver" />
        </MenuItem>
        {/* <MenuItem onClick={handleDownloadFile}>
          <GetAppIcon className={classes.icon} />
          <FormattedMessage id="Descargar archivo" />
        </MenuItem> */}

        {/*  <MenuItem onClick={handleDownload}>
          <GetAppIcon className={classes.icon} />
          <FormattedMessage id="Descargar certificado" />
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default connect(undefined, { push })(Actions);
